.custom-carousel {
    .carousel-indicators {
        li {
            width: 15px;
            height: 15px;
            border-radius: 100%;
            background-color: #e6e8e8;
            &.active {
                background-color: $primary; } } }
    .carousel-caption {
        background-color: rgba(255,255,255,0.5);
        right: auto;
        top: 170px;
        bottom: 70px;
        max-width: 500px;
        color: $body-color;
        h4, h4 p {
            font-weight: 300;
            font-size: 2.8rem !important; }
        p {
            font-size: 1.2rem !important; }
        .btn {
            border-radius: 0; } } }
